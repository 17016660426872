








import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart } from '@/models'
import { VueApexCharts as ApexChart, ApexCharts, es } from '@/plugins/apexcharts'

@Component({
  components: {
    ApexChart
  }
})
export default class MixedChart extends Vue {
  @Prop({ type: Object, required: true }) chart ! : Chart
  @Prop({ type: Object, required: true }) result ! : any
  @Prop({ type: Object, default: () => ({}) }) queryOptions ! : Record<string, any>
  @Prop({ type: Boolean, default: false }) preview ! : boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams ! : Record<string, any>
  @Prop({ type: Boolean, default: false }) loading !: boolean

  get options () : ApexCharts.ApexOptions {
    const options = this.result && this.result.options || {}
    // TODO: Find a better way
    if (options.yaxis && options.yaxis.labels && options.yaxis.labels.formatter) options.yaxis.labels.formatter = eval(options.yaxis.labels.formatter)
    if (options.xaxis && options.xaxis.labels && options.xaxis.labels.formatter) options.xaxis.labels.formatter = eval(options.xaxis.labels.formatter)
    if (options.dataLabels && options.dataLabels.formatter) options.dataLabels.formatter = eval(options.dataLabels.formatter)
    return options
  }

  get height () {
    return this.result && this.result.height || '400'
  }

  get series () {
    return this.result && this.result.series || []
  }

  get type () {
    return this.result && this.result.type || []
  }
}
